import React from 'react';
import {Drawer} from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';

import Form from './PDFTemplate';

export default function BasicModal(props) {

  return (
    <div>
      <Drawer
        anchor='right'
        sx={{ '& .MuiDrawer-paper': { width: 650, background: "#3c3c3c"} }}
        onClose={props.handleClose}
        open={props.open}
      >
        <PDFViewer width="100%" height="100%" className="app" >
          <Form data={props.data} />
        </PDFViewer>
      </Drawer>
    </div>
  );
}
