import React, { useState, useEffect } from 'react';
import {Container} from '@mui/material';

import DataGrid from '../components/DataGrid';

import {CheckInList} from '../services/CheckINs';

export default function CheckINs() {
  const [listCheckIns, setListCheckIns] = useState([]);

  useEffect(() => {
    getListCheckINs();

    const eventInterval = setInterval(async() => {
      await getListCheckINs();
    }, 180000);
    return () => {
      clearInterval(eventInterval);
    }
  }, []);

 
  /*call the function with the Navixy api, which brings the check-in form "BITACORA ENTREGA A TIENDAS" and assigns it to the state "dataForExcel".
  parameters:
    - array dateRangeSearched: date range with which the search will be performed. [0]: start date, [1]: end date (the value can be empty).*/
  const getListCheckINs = async() => {
    setListCheckIns(await CheckInList());
  }

  return ( 
    <div className="backPage"  >
      <Container maxWidth={false} className="containerPage">
        <DataGrid row={listCheckIns}/>
      </Container>
    </div>
  );
}


