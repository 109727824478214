import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CheckINs from '../views/CheckINs';

export default function Routess() {
    return (
    <Router>
        <Routes>
            <Route path="/" exact element={<CheckINs />} />
        </Routes>
    </Router>
    );
}
