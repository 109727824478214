import React, {useState}  from "react";
import { 
  DataGrid, 
  useGridApiContext,
  GridToolbarQuickFilter, 
  GridPagination,
  gridPageCountSelector, 
  useGridSelector
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import {
  Box,
  LinearProgress,
  Pagination,
} from '@mui/material';

import NoData from './NoData';
import EndpointActions from './EndpointActions'


//MUI component function for returning the paging component with page change actions 
function PerformPaging({page, onPageChange, className}){
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(any, newPage) => {
        onPageChange(any, newPage - 1);
      }}
    />
  );
}
//MUI component function to generate and return pagination based on number of rowsa
function CustomPagination(props) {
  return <GridPagination ActionsComponent={PerformPaging} {...props} />;
}


export default function DataGridCheckIN (props) {

  const columns = [
    { field: 'date', headerName: 'Fecha', minWidth: 100, flex: .5 },
    { field: 'id', headerName: 'No. Remisión', minWidth: 130, flex: .5 },
    { field: 'planta', headerName: 'Planta', minWidth: 150, flex: 1 },
    { field: 'cliente', headerName: 'Cliente', minWidth: 250, flex: 1 },
    { field: 'obra', headerName: 'Obra', minWidth: 250, flex: 1 },
    { field: 'operador', headerName: 'Operador', minWidth: 250, flex: 1 },
    { 
      field: '',
      headerName: '', 
      minWidth: 60, sortable: false,
      renderCell: (params) => ( //custom cell, add action components 
        <EndpointActions data={params.row}/>
      ),
    }
  ];

  function CustomToolbar() {
    const apiRef = useGridApiContext();
   
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          m: 2
        }}
      >
        <GridToolbarQuickFilter sx={{ mt: 1.8 }} />
      </Box>
    );
  }

  return ( 
    <Box sx={{ height: 650 }}>
      <DataGrid //
        rows={props.row}
        columns={columns}
        rowHeight={40}
        loading={props.row.length > 0? false:true}
        slots={{ 
          loadingOverlay: LinearProgress,
          toolbar: CustomToolbar , 
          noRowsOverlay: NoData, 
          pagination: CustomPagination,
        }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
        localeText={ esES.components.MuiDataGrid.defaultProps.localeText }
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        disableColumnMenu 
        disableRowSelectionOnClick
      />
    </Box>
  );
}

