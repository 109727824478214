import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';

import logo from '../img/logo.png'
import recomendaciones from '../img/recomendaciones.png'

// Create styles
const styles = StyleSheet.create({
  page: {fontSize: 9,paddingTop: 10,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column',color: "#3E3E3E"},

  spaceBetween : {flex : 1,flexDirection: 'row',textAlign:'left',justifyContent:'space-between'},

  titleContainer: {flexDirection: 'row',marginTop: 10,borderColor : 'whitesmoke',borderBottomWidth:1},
  
  logo: { width: 90 },
  
  theader : {textAlign: 'center',fontWeight:'bold',paddingTop: 4 ,paddingLeft: 4,flex: 1,height: 20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth: 1,borderBottomWidth: 1},

  theader2 : { flex:2, borderRightWidth:0, borderBottomWidth:1},

  tbody:{ paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},

  tbody2:{ flex:2, borderRightWidth:1, },
  
});

// Create Document Component
export default function PDFViewer(props) {
  let data = props.data;
  
	return (
    <Document title={'RemisiónNo-'+ data.id}>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleContainer}>
          <View style={styles.spaceBetween}>
            <View style={{flex : 1}}>
              <Image style={styles.logo} src={logo} />
            </View>
            <View style={{ flex : 1,textAlign: 'center',fontSize:8}}>
              <Text style={{fontWeight: 'bold'}}>Concretos y Materiales Fuerza Técnica SA de CV</Text>
              <Text style={{fontWeight: 'bold'}}>RFC: CMF1307086Z7</Text>
              <Text style={{fontWeight: 'bold'}}>Planta Rosarito</Text>
              <Text>Camino Vecinal 1106,Playas de Rosarito, B.C</Text>
              <Text>Tel. (661) 100 60 98</Text>
            </View>
          </View>
        </View>

        <View style={styles.titleContainer}>
          <View style={styles.spaceBetween}>
            <Text style={{fontWeight: 'bold'}}>Fecha: </Text> <Text> {data.date} </Text>
            <Text style={{fontWeight: 'bold'}}>Planta: </Text> <Text> {data.planta} </Text>
            <Text style={{fontWeight: 'bold'}}>Cliente: </Text> <Text> {data.cliente} </Text>
            <Text style={{fontWeight: 'bold'}}>Remisión No. </Text> <Text style={{color: 'red', fontSize: 16}}> {data.id} </Text>
          </View>
        </View>
        <View style={styles.titleContainer}>
          <Text style={{fontWeight: 'bold'}}>Obra </Text> 
          <Text> {data.obra} </Text>
        </View>

        <View style={{ flexDirection :'row'}}>
          <View style={styles.theader}>
            <Text >Cantidad</Text>   
          </View>
          <View style={[styles.theader, styles.theader2]}>
            <Text>Producto/Servicio</Text>   
          </View>
          <View style={styles.theader}>
            <Text>Resistencia</Text>   
          </View>
          <View style={styles.theader}>
            <Text>Edad</Text>   
          </View>
          <View style={styles.theader}>
            <Text>Colocación</Text>   
          </View>
          <View style={styles.theader}>
            <Text>TMA</Text>   
          </View>
          <View style={styles.theader}>
            <Text>Revenimiento</Text>   
          </View>
        </View>
        <View style={{ width:'100%', flexDirection :'row'}}>
          <View style={styles.tbody}>
            <Text> {data.cantidad} </Text>   
          </View>
          <View style={[styles.tbody, styles.tbody2]}>
            <Text> {data.productoServicio} </Text>   
          </View>
          <View style={styles.tbody}>
            <Text> {data.resistencia} </Text>   
          </View>
          <View style={styles.tbody}>
          <Text> {data.edad} </Text>   
          </View>
          <View style={styles.tbody}>
            <Text> {data.colacion} </Text>   
          </View>
          <View style={styles.tbody}>
            <Text> {data.TMA} </Text>   
          </View>
          <View style={styles.tbody}>
            <Text> {data.revenimiento} </Text>   
          </View>
        </View>

        <View style={{ flexDirection :'row'}}>
          <View style={styles.theader}>
            <Text >No. Viaje</Text>   
          </View>
          <View style={[styles.theader, styles.theader2]}>
            <Text>m³ pedidos/unidades</Text>   
          </View>
          <View style={[styles.theader, styles.theader2]}>
            <Text>m³ entregados/unidades</Text>   
          </View>
          <View style={[styles.theader, styles.theader2]}>
            <Text>m³ restantes/unidades</Text>   
          </View>
        </View>
        <View style={{ width:'100%', flexDirection :'row'}}>
          <View style={styles.tbody}>
            <Text> {data.noViaje} </Text>   
          </View>
          <View style={[styles.tbody, styles.tbody2]}>
            <Text> {data.pedidos} </Text>
          </View>
          <View style={styles.tbody}>
            <Text> {data.entregados} </Text>
          </View>
          <View style={styles.tbody}>
            <Text> {data.restantes} </Text>
          </View>
        </View>
        
        <Text style={{fontWeight: 'bold',textAlign: 'justify',paddingTop: 5,borderColor : 'whitesmoke',borderBottomWidth:1}}>
          La compañía no se hace responsable del concreto suministrado si se le agrega agua en la obra, 
          asi como cualquier otro aditivo que se dosifique sin conocimiento de la empresa 
        </Text>

        <View style={styles.titleContainer}>
          <Text style={{fontWeight: 'bold'}}>Aditivos y/o Servicios </Text> 
          <Text> {data.aditivosServicios} </Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={{fontWeight: 'bold'}}>Para uso exclusivo de Concretera</Text> 
          <Text> {data.exclusivoConcretera} </Text>
        </View>
        <View style={styles.titleContainer}>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Elemento </Text> 
            <Text> </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>No. de pedido </Text> 
            <Text> {data.noPedido} </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Mapa </Text> 
            <Text> {data.mapa} </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Recibe </Text> 
            <Text> {data.recibe} </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Atención </Text> 
            <Text> {data.atencion} </Text>
          </View>
        </View>
        <View style={styles.titleContainer}>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Hora salida de planta</Text> 
            <Text> {data.salidaPlanta} </Text>
            <Text style={{fontWeight: 'bold'}}>Hora fin de descarga</Text> 
            <Text> {data.finDescarga} </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Hora llegada a obra</Text> 
            <Text> {data.llegadaObra} </Text>
            <Text style={{fontWeight: 'bold'}}>Hora entrada a planta</Text> 
            <Text> {data.entradaPlanta} </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Hora inicio de descarga</Text> 
            <Text> {data.inicioDescarga} </Text>
          </View>
        </View>
        <View style={styles.titleContainer}>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>No. de revolvedora</Text> 
            <Text> {data.noRevolvedora} </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Operador/Nombre</Text> 
            <Text> {data.operador} </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>No de empleado</Text> 
            <Text> {data.noEmpleado} </Text>
          </View>
          <View style={{flex : 1}}>
            <Text style={{fontWeight: 'bold'}}>Firma</Text> 
            <Image style={styles.logo} src={data.firmaOperador} />
          </View>
        </View>

        <Text style={{fontWeight: 'bold',paddingTop: 5,borderColor : 'whitesmoke',borderBottomWidth:1}}>
          Estimado cliente, agradecemos evaluar nuestro servicio en los siguientes conceptos
        </Text>
        <View style={styles.titleContainer}>
          <View style={styles.spaceBetween}>
            <Text style={{fontWeight: 'bold'}}>Puntualidad </Text> <Text> {data.puntualidad} </Text>
            <Text style={{fontWeight: 'bold'}}>Amabilidad </Text> <Text> {data.amabilidad} </Text>
            <Text style={{fontWeight: 'bold'}}>Imagen </Text> <Text> {data.imagen} </Text>
            <Text style={{fontWeight: 'bold'}}>Seguridad </Text> <Text> {data.seguridad} </Text>
          </View>
        </View>
        <View style={styles.titleContainer}>
          <View>
            <Text style={{fontWeight: 'bold'}}>Firma cliente y/o persona autorizada </Text>
            <Image style={styles.logo} src={data.firmaCliente} />
          </View>
        </View>

        <View style={styles.titleContainer}>
          <Text style={{fontWeight: 'bold'}}>Comentarios adicionales</Text> 
          <Text> {data.cometarios} </Text>
        </View>
        <View style={styles.titleContainer}>
            <Text style={{fontWeight: 'bold'}}>Folio: </Text> <Text> {data.folio} </Text>
        </View>

      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={{fontWeight: 'bold',paddingTop: 5}}>
            RECOMENDACIONES PARA OBTENER MEJORES RESULTADOS EN LA CONSTRUCCIÓN DE OBRAS DE CONCRETO
          </Text>
        </View>

        <View style={styles.titleContainer}>
          <Image src={recomendaciones} />
        </View>

        <View style={styles.titleContainer}>
          <View style={{textAlign: 'justify'}}> 
            <Text>° Toda reclamación del Cliente se hará por escrito en el domicilio correspondiente a la unidad de negocio encargada de sumunitar los Productos y/o Serviocios reclamados.</Text>
            <Text>° Si la recalamación es por Servicioo volumen, el Clliente deberá formularla dentro de los 2 días hábiles siguiente al suministro.</Text>
            <Text>° Si la reclamación es por porblemas que surja respecto del Producto y/o Servicio durante las 24 horas naturales siguientes al suministro, el Cliente deberá formularla dentro de los 2 días hábiles siguientes al suministro.</Text>
            <Text>° Si la reclamación es por la resistencia o calidad del Producto, el Cliente deberá formular su reclamación dentro de los 33 días naturales siguientes al suministro.</Text>
            <Text>° Si la reclamación es por la apariencia del producto, el Cliente deberá formular su reclamación dentro de los 14 días naturales siguientes al suministro.</Text>
            <Text>° Si la reclamación es por fraguado, el Cliente deberá formular su reclamación dentro de las 24 horas siguientes al suministro.</Text>
            <Text>° Se entenderá que existe plena conformidad del Cliente respecto de los Productos y/yo Servicios suministrados, si agotados los términos indicados según el tipo de reclamación que se trate, no media reclamación alguna.</Text>
            <Text>° En caso de controversia sobre la calidad del Producto, se atenderá el procedimiento establecido en la NMX-C-155 vigente que las partes manifiestan conocer. Una vez entregado el Produto, su colocación, compactación, curado o cualquier otro tratamiento o manejo, así como las consecuencias que de ello se deriven serán de la responsabilidad exclusiva del cliente.</Text>
          </View>
        </View>

        <Text style={{fontWeight: 'bold',paddingTop: 5,borderColor : 'whitesmoke',borderBottomWidth:1}}>
          Evaluación del operador
        </Text>
        <View style={styles.titleContainer}>
          <View style={styles.spaceBetween}>
            <Text style={{fontWeight: 'bold'}}>Accesos </Text> <Text> {data.accesos} </Text>
            <Text style={{fontWeight: 'bold'}}>Tiempo de espera </Text> <Text> {data.tiempoEspera} </Text>
          </View>
        </View>

      </Page>
    </Document>
  );
}
