import axios from 'axios';
let session_key = "58c541faae7ad9afa671700cad1aa8a2";

/**
 * 
 * @param {*} dateRangeSearched 
 * @param {*} searchBy 
 * @returns 
 */
/* Returns an array with the chek-in related to the form "BITACORA DELIVERY TO STORES", using Navixy's task and form API. 
    (this form uses a static ID, in case you need to bring another form edit the value sent to the "template_id" parameter. 
    If you change it, change also the assigned elements accordingly).
  parameters:
    - array dateRangeSearched: date range with which the search will be performed. [0]: start date, [1]: end date (the value can be empty).
    - string session_key: hash of the user that will be used to query the Navixy API.*/
export async function CheckInList() {
    var list=[];

    try {
        // static "template_id" parameter
        await axios.post(`https://api.navixy.com/v2/task/form/list?hash=${session_key}&include_unsubmitted=false&template_id=41888`)
        .then((response) => { 
            var planta = response.data.list[0].form.fields[0].options,
            productoServicio = response.data.list[0].form.fields[5].options,
            accesos = response.data.list[0].form.fields[31].options,
            tiempoEspera = response.data.list[0].form.fields[32].options,
            calificaciones = response.data.list[0].form.fields[35].options;

            response.data.list.map( 
                async function createList(element){ //a list is created with the specific data to be used from the chek-in list
                    list.push({
                        //All elements are static and are related to the form ID. For any change, first validate the original API response.
                        "id":element.form.id,
                        "date": new Date(element.form.submitted).toLocaleString("es-MX", {year:"numeric", month:"numeric", day:"numeric"}),
                        "planta": planta[element.form.values['mhz3uz'].value_index].label, //dropdown
                        "cliente": element.form.values['qp6r8f'].value, 
                        "obra": element.form.values['dyzj2c'].value,

                        "cantidad": element.form.values['vdxccj'].value,
                        "productoServicio": productoServicio[element.form.values['wr97co'].value_index].label, //dropdown
                        "resistencia":element.form.values['9p6evj'] === undefined? '' : element.form.values['9p6evj'].value, 
                        "edad": element.form.values['gzvp7u'] === undefined? '' : element.form.values['gzvp7u'].value, 
                        "colacion": element.form.values['810xc4'] === undefined? '' : element.form.values['810xc4'].value, 
                        "TMA": element.form.values['bhnoud']  === undefined? '' : element.form.values['bhnoud'].value, 
                        "revenimiento": element.form.values['fpgeb6']  === undefined? '' : element.form.values['fpgeb6'].value,
                        "noViaje": element.form.values['ozeihj'].value,
                        "pedidos": element.form.values['p7pst9'].value,
                        "entregados": element.form.values['xhcmk5'].value,
                        "restantes": element.form.values['8fdlwv'].value,
                        
                        "aditivosServicios": element.form.values['hzn1ly'] === undefined? '' : element.form.values['hzn1ly'].value,
                        "exclusivoConcretera": element.form.values['vouciw'] === undefined? '' : element.form.values['vouciw'].value,
                        "elemento": element.form.values['q57tcx'] === undefined? '' : element.form.values['q57tcx'].value,
                        "noPedido": element.form.values['gruwos'].value,
                        "mapa": element.form.values['dp4tma'] === undefined? '' : element.form.values['dp4tma'].value,
                        "recibe": element.form.values['ootqpn'] === undefined? '' : element.form.values['ootqpn'].value,
                        "atencion": element.form.values['vrit7e'] === undefined? '' : element.form.values['vrit7e'].value,
                        
                        "noRevolvedora": element.form.values['qkj3is'].value,
                        "salidaPlanta": element.form.values['0a39s8'].value,
                        "llegadaObra": element.form.values['eunanb'].value,
                        "inicioDescarga": element.form.values['kl3jsx'].value,
                        "finDescarga": element.form.values['ri6t0b'].value,
                        "entradaPlanta": element.form.values['hs1a8i'].value,
                        
                        "accesos": element.form.values['iejmk7'] === undefined? '' : accesos[element.form.values['iejmk7'].value_index].label, //radio
                        "tiempoEspera": element.form.values['jf2slz'] === undefined? '' : tiempoEspera[element.form.values['jf2slz'].value_index].label, //radio
                        "operador": element.employee.first_name +" "+ element.employee.last_name + " " + element.employee.middle_name,
                        "noEmpleado": "",
                        "firmaOperador": element.files[1].view_url,
                        
                        "puntualidad": element.form.values['d42yxn'] === undefined? '' : calificaciones[element.form.values['d42yxn'].value_index].label, //radio
                        "amabilidad": element.form.values['dogxjw'] === undefined? '' :calificaciones[element.form.values['dogxjw'].value_index].label, //radio
                        "seguridad": element.form.values['g80yy9'] === undefined? '' : calificaciones[element.form.values['g80yy9'].value_index].label, //radio
                        "imagen": element.form.values['hjqb5f'] === undefined? '' : calificaciones[element.form.values['hjqb5f'].value_index].label, //radio
                        "cometarios": element.form.values['faj2sk'] === undefined? '' : element.form.values['faj2sk'].value,
                        "firmaCliente": element.files[0].view_url,
                        "folio": element.form.values['rj0880'].value,
                    }) 
                    list[list.length-1].noEmpleado = await Employee(element.employee.id);
                }
            );
        });
    } 
    catch (err) { }
    return list;
}

export async function Employee(id) {
    let noEmpleado="";
    try {
        noEmpleado = (await axios.post(`https://api.navixy.com/v2/employee/read?hash=${session_key}&employee_id=${id}`)).data.value.personnel_number;
    } 
    catch (err) { }

    return noEmpleado;
}