import React, { useState, Fragment } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import {Preview,ForwardToInbox} from '@mui/icons-material';

import PDFViewer from './PDFViewer';

export default function EndpointActions(props) {
  const [open, setOpen] = useState(false);
  const handleClose = () => { setOpen(false); }
  

  return (
    <Fragment>
    {/*Reset connectivity
      <Tooltip title="Reenviar formato" arrow>
        <span>
          <IconButton color="primary">
            <ForwardToInbox />
          </IconButton>
        </span>
      </Tooltip>*/}
    {/*PDF viewer*/}
      <Tooltip title="Ver PDF" arrow>
        <span>
          <IconButton color="primary">
            <Preview onClick={()=>{setOpen(true);}} />
          </IconButton>
        </span>
      </Tooltip>

      <PDFViewer open={open} handleClose={handleClose} data={props.data}/>
    </Fragment>
  );
}
